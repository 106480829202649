<script>
import { ApigetPageconfig } from "@api/service";
export default {
  created() {
    this.getIco();
    this.$store.dispatch("getAppConfig");
    this.$store.dispatch("getWebUrls");
    //设置ico
  },
  methods: {
    async getIco() {
      let config = {};
      let configPage = {
        configName: process.env.VUE_APP_CURRENTMODE == "dev" ? "" : null,
      };
      const res = await ApigetPageconfig(configPage, [
        "site_config",
        "landing_config",
      ]);
      res.data.forEach((c) => {
        c.configValue = JSON.parse(c.configValue);
        config[c.configKey] = c;
      });
      const ico =
        'https://qny-zhzy.tigertechnology.com.cn' +
        config.landing_config.configValue.project_path +
        "/ico.ico";
      document.title = config.landing_config.configValue.title?config.landing_config.configValue.title:`欢迎`
      const link = document.createElement("link");
      link.setAttribute("href", ico);
      link.setAttribute("rel", "shortcut icon");
      document.getElementsByTagName("head")[0].appendChild(link);
    },
  },
};
</script>
