<script>
export default {
  computed: {
    url() {
      return (
        `https://qny-zhzy.tigertechnology.com.cn` +
        ((this.$store.state.appConfig.landing_config || {}).configValue || {})
          .project_path +
        "/"
      );
    },
    swiperImg_list() {
      return (
        (this.$store.state.appConfig.landing_config || {}).configValue || {}
      ).swiperImg_list;
    },
  },
};
</script>
