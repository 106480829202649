import Vue from 'vue'
import Vuex from 'vuex'
import { ApigetPageconfig, ApigetWebUrls } from "@api/service";
import { get } from "lodash-es";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

//获取本地缓存的配置
// const DEFAULT_APPCONFIG = {
//   lottery_config: {
//     configValue: {
//       supportPlayway: 3, //1表示只支持官方2表示只支持信用3表示官方信用都支持
//       defaultPlayway: 2, //1表示默认官方2表示默认信用
//       xyPlaywayDefaultBetAmountMode: 0, //0快捷 1一般
//     },
//   }
// };

const APPCONFIG = {};
// try {
//   if (localStorage.NEWAPPCONFIG) {
//     APPCONFIG = Object.assign({}, DEFAULT_APPCONFIG, JSON.parse(localStorage.NEWAPPCONFIG));
//   }
// } catch (e) {
//   console.warn(e);
//   APPCONFIG = DEFAULT_APPCONFIG;
// }

export default new Vuex.Store({
  state: {
    appConfig: APPCONFIG,
    webUrls: {},
  },
  getters: {
    cdn() {
      return 'https://qny-zhzy.tigertechnology.com.cn'
    },
    appkey(state) {
      return get(state, "appConfig.landing_config.configValue.appkey", "");
    },
    appShareSdkPlatform(state) {
      return get(state, "appConfig.landing_config.configValue.appShareSdkPlatform", "");
    },
    getCommonUrl: (state, getters) => (nameWithExt) => {
      return `${getters.cdn
        }/${get(state, "appConfig.landing_config.configValue.commomImg_name", "")}/${nameWithExt}`;
    },
    getUrl: (state, getters) => (resourceName) => {
      const configKey = get(state, `appConfig.landing_config.configValue.${resourceName}`, "");
      if (!configKey) {
        console.log('数据库中没有配置该字段')
        return "";
      }
      return `${getters.cdn
        }${get(state, "appConfig.landing_config.configValue.project_path", "")}/${configKey}`;
    }
  },
  mutations: {
    setAppConfig(state, payload) {
      state.appConfig = payload;

      localStorage.NEWAPPCONFIG = JSON.stringify(payload);
    },
    setWebUrls(state, payload) {
      state.webUrls = payload;
    }
  },
  actions: {
    async getAppConfig({ commit }) {
      console.log(process.env.VUE_APP_CURRENTMODE)
      let configPage = {
        configName: process.env.VUE_APP_CURRENTMODE == 'dev' ? "" : null
      }
      const res = await ApigetPageconfig(configPage, ["site_config", "landing_config"]);
      if (res.code == "12200") {
        let config = {};
        try {
          res.data.forEach((c) => {
            c.configValue = JSON.parse(c.configValue);
            config[c.configKey] = c;
          });
          config = Object.assign({}, APPCONFIG, config);
          commit("setAppConfig", config);
        } catch (e) {
          commit("setAppConfig", APPCONFIG);
          console.warn(e);
        }
      }
    },
    async getWebUrls({ commit }) {
      const res = await ApigetWebUrls([
        "web_landing_page_domain1",
        "web_landing_page_domain2",
        "app_address",
        "app_address_fixed",
        "official_kefu_url"
      ]);
      if (get(res, 'code') == "12200") {
        commit("setWebUrls", get(res, 'data'));
      }
    }
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
